import React, { useEffect, useState } from 'react';
import service, {USER_API} from '../../services/service';
import UserService from '../../services/UserService';

const TokenImage = ({ path }) => {
    const token = UserService.getToken();
  const [imgSrc, setImgSrc] = useState('');

  useEffect(() => {
    const fetchImage = async () => {
      const url = `${USER_API}getprofilepicforadminverification/${path}`;
      try {
        const response = await fetch(url, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const blob = await response.blob();
        const imgUrl = URL.createObjectURL(blob);
        setImgSrc(imgUrl);
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
      }
    };

    fetchImage();
  }, [path, token]);

  return (
    <img
      src={imgSrc}
      alt="Profile"
      style={{ width: '42px', height: '42px', borderRadius: '100%', boxShadow: "5px 10px 20px 1px rgba(0, 0, 0, 0.253)" }}
    />
  );
};

export default TokenImage;
