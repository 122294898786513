import axios from 'axios';
import HttpService from "./HttpService";
import service from "./service";
import React, { useState, useEffect, useMemo } from "react";
import { Circles } from 'react-loader-spinner';

export let generalLoader = () => {
  return(<div
    style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.7)', // Adjust the transparency as needed
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000, // Ensure it's above other elements
    }}
>

    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Circles height={40} width={40} color="#4fa94d" ariaLabel="circles-loading" />
    </div>
</div>)
}