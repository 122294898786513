import React, { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';

export const CustomEditor = ({ content, setContent }) => {
    const editorRef = useRef(null);
    const onBlurHandler = () => {        
        const newContent = editorRef.current.getContent();
        setContent(newContent)
    }
    return (
        <div>
            <Editor
                tinymceScriptSrc={process.env.PUBLIC_URL +
                    '/tinymce/tinymce.min.js'}
                onInit={(evt, editor) => { editorRef.current = editor }}
                initialValue={content}
                init={{
                    height: 200,
                    menubar: false,
                    plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'image',
                        'charmap',
                        'anchor', 'searchreplace', 'visualblocks', 'code',
                        'fullscreen',
                        'insertdatetime', 'media', 'table', 'preview',
                        'help', 'wordcount'
                    ],
                    toolbar: 'undo redo | blocks | ' +
                        'bold italic forecolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdentindent | ' +
                        'removeformat | help',
                    content_style: 'body {font - family:Helvetica,Arial,sansserif;font-size:14px }',
                    
                }}
                onBlur={onBlurHandler}
            />
        </div>
    )
}