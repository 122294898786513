import styled from "styled-components";
import { colors } from "../../../components/common/element/elements.js";

export const Styles = styled.div`
    .dashboard-page {
        .dashboard-page-area {
            padding : 70px 0;
            color      : ${colors.blue};

        }
    }
`;