import React, { useState, useMemo, useEffect } from 'react';
import { Styles } from './styles/dashborad.js'
import { BreadcrumbBox } from '../../components/common/Breadcrumb'
import FooterTwo from '../../components/FooterTwo'
import HeaderTwo from '../../components/HeaderTwo'
import { Container, Row, Col, Button, Modal, Card } from 'react-bootstrap';
import DataTable from "react-data-table-component";
import FilterDataTable from '../../pages/instructor/FilterDataTable';
import UserService from '../../services/UserService.js';
import service from '../../services/service.js';
import learnerService from '../../services/learnerService';
import { ConsoleView } from 'react-device-detect';

import { useTranslation } from 'react-i18next'
import cookies from 'js-cookie';
import moment from 'moment';
import adminServices from '../../services/adminServices.js';
import swal from 'sweetalert';
import ProgressBar from '@ramonak/react-progress-bar';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';


const languages = [

    {
        code: 'en',
        name: 'English',
        country_code: 'gb',
    },

    //{
    //     code: 'hi',
    //        name: 'Hindi',
    //        country_code: 'in'
    //    },
    // {
    //     code: 'te',
    //     name: 'Telugu',
    //     country_code: 'in'
    // },
    // {
    //     code: 'pu',
    //     name: 'Punjabi',
    //     country_code: 'in'
    // },
    // {
    //     code : 'mr',
    //     name : 'Marathi',
    //     country_code : 'in'
    // }
]

//////////////////////////////////////////////// COURSE SUMMARY START ////////////////////////////////////////////
const customStylesForSummary = {
    title: {
        style: {
            fontColor: 'red',
            fontWeight: '900',
        }
    },
    headCells: {
        style: {
            fontSize: '17px',
            fontWeight: '500',
            paddingLeft: '0 8px',
            marginLeft: '10px',
        },
    },
    cells: {
        style: {
            fontSize: '15px',
            paddingLeft: '0 8px',
            marginLeft: '10px'
        },
    },
};

const stylesForSummary = {
    summaryContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    summaryItem: {
        textAlign: 'center',
        padding: '10px',
        border: '1px solid #ddd',
        borderRadius: '5px',
        margin: '5px',
        width: '45%',
    },
    summaryLabel: {
        fontWeight: 'bold',
        marginBottom: '5px',
    },
    summaryValue: {
        fontSize: '18px',
        color: '#007BFF',
    },
};
//////////////////////////////////////////////// COURSE SUMMARY END ////////////////////////////////////////////

const customStyles = {
    title: {
        style: {
            fontColor: 'red',
            fontWeight: '900',
        }
    },
    headCells: {
        style: {
            fontSize: '17px',
            fontWeight: '500',
            paddingLeft: '0 8px',
            marginLeft: '10px',
        },
    },
    cells: {
        style: {
            fontSize: '15px',
            // paddingLeft: '0 8px',
            // marginLeft: '10px'
        },
    },
};

function InstructorDashboard(props) {
    const currentLanguageCode = cookies.get('i18next') || 'en'
    const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
    const { t } = useTranslation()

    useEffect(() => {
        document.body.dir = currentLanguage.dir || 'ltr'
        document.title = t('app_title')
    }, [currentLanguage, t])

    useEffect(() => {
        UserService.generateToken();
    }, []);

    let tenantId = 1;
    const [moreDetailsDiv, setMoreDetailsDiv] = useState(false);
    const [getEmailListDiv, setEmailListDiv] = useState(false);
    const [selectedState, setSelectedState] = useState();
    const [getLearnerData, setlearnerData] = useState([]);
    const [getCurrentUserId, setCurrentUserId] = useState();
    const [getQuizModal, setQuizModal] = useState({
        show: false
    });

    const [getQuizReportCard, setQuizReportCard] = useState([]);
    const [getSelectedAttempt, setSelectedAttempt] = useState();

    const setQuizModalHide = () => {
        setQuizModal({
            show: false
        });
    }

    const onEmailIdSelectHandler = () => {
        setEmailListDiv(true)
    }
    const onSelectHandler = (e) => {
        setSelectedState(e.target.value);
    }

    const quizReportInfo = (currentUserId, quizId) => {

        const data = {
            userId: currentUserId,
            quizId: quizId,
            attemptNumber: 0,
            courseId: getCourseId,
            templateId: "templateId",
            adminKey: "adminKey"
        }
        service.getQuizCompleteResult(data)
            .then(res => {
                
                setQuizReportCard(res.data);
            })
            .catch(err => {

            })
    }

    const courseOutlineReportcolumns = [
        {
            name: "S.No",
            selector: row => row.id,
            sortable: true,
            width: "80px"
        },
        {
            name: "Email ID",
            selector: row => row.emailid,
            sortable: true,
            wrap: true,
        },
        {
            name: "First Name",
            selector: row => row.firstName,
            sortable: true,
            wrap: true,
        },
        {
            name: "Last Name",
            selector: row => row.lastName,
            sortable: true,
            wrap: true,
        },
        {
            name: "Progress",
            selector: row => row.progress,
            sortable: true,
            wrap: true,
        },
        {
            name: "Quiz Status",
            selector: row => row.quizStatus,
            sortable: true,
            wrap: true
        },
        {
            name: "Assign Status",
            selector: row => row.assignStatus,
            sortable: true,
            wrap: true,
        },
        {
            name: "Certificate",
            selector: row => row.certificate,
            sortable: true,
            wrap: true
        },
        {
            name: "Course Status",
            selector: row => row.courseStatus,
            sortable: true,
            wrap: true
        },
        {
            name: "Total Time Spent",
            selector: row => row.totalTimeSpent,
            sortable: true,
            wrap: true
        }
    ];

    const assignmentReportColumns = [
        {
            name: "S.No",
            selector: row => row.id,
            sortable: true,
            width: "80px"
        },
        {
            name: "Assign ID",
            selector: row => row.assignid,
            sortable: true,
            wrap: true,
        },
        {
            name: "Submission Date",
            selector: row => row.submissiondate,
            sortable: true,
            wrap: true
        },
        {
            name: "Marks",
            selector: row => row.marks,
            sortable: true,
            wrap: true
        },
        {
            name: "Remarks",
            selector: row => row.remarks,
            sortable: true,
            wrap: true
        }
    ];

    const quizReportcolumns = [
        // {
        //     name: "S.No",
        //     selector: row => row.id,
        //     sortable: true,
        //     width: "80px"
        // },
        {
            name: "Quiz ID",
            selector: row => row.quizId,
            sortable: true,
            wrap: true,
        },
        {
            name: "Quiz Title",
            selector: row => row.quizTitle,
            sortable: true,
            wrap: true
        },
        {
            name: "Valid From",
            // selector: row => row.validFrom,
            selector: row => moment(row.validFrom).format('MM-DD-YYYY HH:mm'),

            sortable: true,
            wrap: true
        },
        {
            name: "Valid To",
            // selector: row => row.validTo,
            selector: row => moment(row.validTo).format('MM-DD-YYYY HH:mm'),
            sortable: true,
            wrap: true
        },
        {
            name: "Attempts",
            selector: row => row.attempts,
            sortable: true,
            wrap: true
        },
        {
            name: 'Action',
            cell: row => <>
                <Button onClick={() => { setQuizModal({ show: true }); quizReportInfo(getCurrentUserId.userId, row.quizId); }} disabled={moreDetailsLoading.isLoading ? "true" : ""} style={{ background: "green", border: "0px" }}> {moreDetailsLoading.isLoading ? (<> {t('loading')}</>) : <>{t('more_details')}</>} </Button>
            </>
        }
    ];

    const timeSpentColumns = [
        {
            name: "S.No",
            selector: row => row.id,
            sortable: true,
            width: "80px"
        },
        {
            name: "Content Name",
            selector: row => row.contentname,
            sortable: true,
            wrap: true,
        },
        {
            name: "Time Spent (HH:MM:SS)",
            selector: row => row.timespent,
            sortable: true,
            wrap: true
        },
    ];

    const contentAccessColumns = [
        {
            name: "S.No",
            selector: row => row.id,
            sortable: true,
            width: "80px"
        },
        {
            name: "Content Name",
            selector: row => row.contentname,
            sortable: true,
            wrap: true,
        },
        {
            name: "Start Time",
            selector: row => row.starttime,
            sortable: true,
            wrap: true
        },
        {
            name: "End Time",
            selector: row => row.endtime,
            sortable: true,
            wrap: true
        }
    ];

    const activityLogColumns = [
        {
            name: "S.No",
            selector: row => row.id,
            sortable: true,
            width: "80px"
        },
        {
            name: "Actitivy",
            selector: row => row.activity,
            sortable: true,
            wrap: true,
        },
        {
            name: "In Time",
            selector: row => row.intime,
            sortable: true,
            wrap: true
        },
        {
            name: "Out Time",
            selector: row => row.outtime,
            sortable: true,
            wrap: true
        }
    ];


    /****************************LIST OF AUTHORED COURSE***************************/


    const [filterText, setFilterText] = React.useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
        false
    );
    useEffect(() => {
        getAuthoredCourseList();
    }, []);

    const [moreDetailsLoading, setMoreDetailsLoading] = useState({
        isLoading: false
    })

    const [authoredCourseList, setAuthoredCourseList] = useState([]);
    const [getCourseId, setCourseId] = useState();
    const userId = UserService.getUserid();
    const getAuthoredCourseList = () => {
        service.getUserEnrolledCourses(userId, 2).then((resp) => {
            setAuthoredCourseList(resp.data);
        }).catch((err) => {
            
        })
    }

    const [quizReportList, setQuizReportList] = useState([]);


    const getQuizReport = (courseId, userId) => {
        service.quizReport(userId, courseId)
            .then((resp) => {
                
                setCurrentUserId(resp.data);
                setQuizReportList(resp.data.assignedQuizzes);
            }).catch((err) => {
                
            })
    }

    const quizfilteredItems = quizReportList.filter(
        item =>
            item.quizTitle
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );

    const authoredCourseListItems = authoredCourseList.filter(
        item =>
            item.courseDetails.courseName
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    )
        .sort((a, b) => new Date(b.courseDetails.commencementDate) - new Date(a.courseDetails.commencementDate));

    const authoredListSubHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        return (
            <FilterDataTable
                onFilter={e => setFilterText(e.target.value)}
                onClear={handleClear}
                filterText={filterText}
            />
        );
    }, [filterText, resetPaginationToggle]);

    const onClickMoreDetails = (id, courseName) => {
        setMoreDetailsLoading({ isLoading: true });
        setMoreDetailsDiv({
            ...moreDetailsDiv,
            condition: true,
            setID: id,
            courseName: courseName,
        })
        setMoreDetailsLoading({ isLoading: false });
        if (selectedState) {
            setMoreDetailsLoading({ isLoading: false });
            document.getElementById('select').value = 'Choose'
            setSelectedState('');

            setMoreDetailsLoading({ isLoading: false });
        }
        //resetError();
    }

    //////////////////////////////////////////////// COURSE SUMMARY START ////////////////////////////////////////////
    const [selectedCourseId, setSelectedCourseId] = useState();
    const [selectedCourse, setSelectedCourse] = useState();
    const [courseSummaryList, setCourseSummaryList] = useState([]);
    const [filteredList, setFilteredList] = useState();

    const [totalEnrolledCourse, setTotalEnrolledCourse] = useState(0);
    const [totalCompletedCourse, setTotalCompletedCourse] = useState(0);
    const [totalInProgressCourse, setTotalInProgressCourse] = useState(0);
    const [totalGenCert, setTotalGenCert] = useState(0);
    const [totalNotStartedYet, setTotalNotStartedYet] = useState(0);

    const [isCourseSummaryLoading, setIsCourseSummaryLoading] = useState(false);

    const [selectedUser, setSelectedUser] = useState(null);
    const [userCourseContentReportList, setuserCourseContentReportList] = useState([]);

    const [filterTextForSummary, setFilterTextForSummary] = useState("");
    const [filterTextContentSummary, setFilterTextContentSummary] = useState("");

    const [filteredItems, setFilteredItems] = useState([]);

    const getCourseSummaryData = (courseId) => {
        setTotalEnrolledCourse(0);
        setTotalCompletedCourse(0);
        setTotalInProgressCourse(0);
        setTotalNotStartedYet(0);
        setTotalGenCert(0);
        setIsCourseSummaryLoading(true);
        try {
            if (courseId) {
                adminServices.getUsersCourseSummaryByCourseId(courseId)
                    .then((res) => {
                        if (res?.status == 200) {
                            setCourseSummaryList(res?.data);
                            setIsCourseSummaryLoading(false);
                        }
                    })
                    .catch((err) => {
                        setCourseSummaryList([]);
                       
                    })
            }
        } catch (err) {
            
            setIsCourseSummaryLoading(false);
            swal(`${t('error')}`, `${t('something_wrong_try_again')}`, "error")
        }
        setIsCourseSummaryLoading(false);
    }

    const calculateCourseSummaryData = () => {

        if (courseSummaryList.length !== 0) {

            setTotalEnrolledCourse(courseSummaryList.length);

            let completedCount = 0;
            let inProgressCount = 0;
            let notStartedCount = 0;
            let totalGenCert = 0;

            if (courseSummaryList[0]?.totalContent == 0) {
                setTotalCompletedCourse(completedCount);
                setTotalInProgressCourse(inProgressCount);
                setTotalNotStartedYet(notStartedCount);
                setTotalGenCert(0);
            } else {
                courseSummaryList.forEach((user) => {
                    // const completed = user.progress === 100
                    // const total = parseInt(user.totalContent);

                    if (user.progress === 100) {
                        completedCount++;
                    } else if (user.progress > 0 && user.progress < 100) {
                        inProgressCount++;
                    } else if (user.progress === 0) {
                        notStartedCount++;
                    }

                    if (user.certDownloadStatus === "GENERATED") {
                        totalGenCert++;
                    }
                });

                setTotalEnrolledCourse(courseSummaryList.length)
                setTotalCompletedCourse(completedCount);
                setTotalInProgressCourse(inProgressCount);
                setTotalNotStartedYet(notStartedCount);
                setTotalGenCert(totalGenCert);
            }
        }
    };

    const onClickGetCourseSummary = (courseId) => {
        setCourseSummaryList([]);
        setuserCourseContentReportList([]);
        setSelectedUser(null);
        setSelectedCourseId(courseId)
        getCourseSummaryData(courseId);
    }

    useEffect(() => {
        calculateCourseSummaryData();
    }, [courseSummaryList])


    useEffect(() => {
        getCourseSummaryData();
    }, [selectedCourseId])

    useEffect(() => {
        if (selectedUser) {
            getUserCourseContentWatchReport();
        }
    }, [selectedUser])


    const handleRowClick = (row) => {
        setSelectedUser(row);
    };

    const convertTimestampToDate = (timestamp) => {
        return moment(timestamp).format('MMMM Do YYYY, h:mm:ss a');
    };

    const getUserCourseContentWatchReport = () => {
        service.getUserCourseContentDetails(selectedUser.userId, selectedUser.courseId)
            .then((res) => {
                
                setuserCourseContentReportList(res.data);
            })
            .catch((err) => {
            })
    }

    const userCourseContentReportfilteredList = userCourseContentReportList?.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterTextContentSummary.toLowerCase()) !== -1
    );

    useEffect(() => {
        if (courseSummaryList.length !== 0) {

            const filteredItem = courseSummaryList?.filter(
                item =>
                    JSON.stringify(item)
                        .toLowerCase()
                        .indexOf(filterTextForSummary.toLowerCase()) !== -1
            )
            setFilteredItems(filteredItem);
        } else {
            setFilteredItems([]);
        }

    }, [courseSummaryList, filterTextForSummary])




    const exportPDF = () => {
        const unit = "pt";
        const size = "A4";
        const orientation = "portrait"
        const doc = new jsPDF(orientation, unit, size);
        doc.setFontSize(15);
        const title = `${selectedCourse.courseName} Report`;
        const headers = [["User Name", "Email", "Course Progress(%)", "Progress Status", "Certficate Status"]];

        var checkData = courseSummaryList.map((data) => [
            `${data.firstName + data.lastName}`
            , `${data.email}`
            , `${data.progress}`
            , `${data.progressStatus}`
            , `${data.certDownloadStatus}`
        ])
        let content = {
            startY: 50,
            head: headers,
            body: checkData
        };
        doc.text(title, 40, 40);
        doc.autoTable(content);
        doc.save(`${selectedCourse.courseName}-Summary-Report.pdf`)
    }

    const exportExcel = () => {

        var checkData = [];
        const headers = ["User Name", "Email", "Course Progress(%)", "Progress Status", "Certficate Status"];

        courseSummaryList.forEach((data) => {
            const UserName = data.firstName + data.lastName;
            const Email = data.email;
            const CourseProgress = data.progress;
            const ProgressStatus = data.progressStatus;
            const CertficateStatus = data.certDownloadStatus;

            checkData.push([UserName, Email, CourseProgress, ProgressStatus, CertficateStatus]);
        });

        const ws = XLSX.utils.aoa_to_sheet([headers, ...checkData]);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, `Course Report`);
        XLSX.writeFile(wb, `${selectedCourse.courseName}-Summary-Report.xlsx`);
    }

    const courseSummarySearchBox = useMemo(() => {
        const handleClear = () => {
            if (filterTextForSummary) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterTextForSummary("");
            }
        };

        return (
            <FilterDataTable
                onFilter={e => setFilterTextForSummary(e.target.value)}
                onClear={handleClear}
                filterTextForSummary={filterTextForSummary}
            />
        );
    }, [filterTextForSummary, resetPaginationToggle]);

    const contentSummarySearchBox = useMemo(() => {
        const handleClear = () => {
            if (filterTextContentSummary) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterTextForSummary("");
            }
        };

        return (
            <FilterDataTable
                onFilter={e => setFilterTextContentSummary(e.target.value)}
                onClear={handleClear}
                filterTextContentSummary={filterTextContentSummary}
            />
        );
    }, [filterTextContentSummary, resetPaginationToggle]);

    const columnsForSummary = [
        {
            name: "Name",
            selector: row => `${row.firstName}`,
            sortable: true,
            wrap: true,
            cell: row =>
                <div
                    style={{
                        cursor: 'pointer'
                    }}
                    onClick={() => {
                        // setSelectedRow(row);
                        // toggleModal();
                    }}>{`${row.firstName} ${row.lastName}`}
                </div>
        },
        {
            name: "Email",
            selector: row => row.email,
            wrap: true,
            sortable: true,
        },
        {
            name: "Contents Progress",
            selector: row => row.progress,
            sortable: true,
            wrap: true,
            width: '300px',
            cell: (row) => (
                <Container>
                    <Row>
                        <Col md={12}>
                            <ProgressBar
                                completed={row.progress || 1}
                                baseBgColor="#e0e0de"
                                maxCompleted={100}
                                bgColor={row.progress === 100 ? "#11B67A" : "#ffae4a"}
                                height='20px'
                                width='100%'
                                animateOnRender={true}
                                isLabelVisible={true}
                                transitionTimingFunction={'ease-in-out'}
                                labelColor='#ffffff'
                                labelSize='10px'
                            />
                        </Col>
                    </Row>
                </Container>
            ),
        },
        {
            name: "Progress Status",
            selector: row => row.progressStatus,
            wrap: true,
            sortable: true,
        },
        {
            name: "Certificate Status",
            selector: row => row.certDownloadStatus,
            wrap: true,
            sortable: true,
        },
    ];

    const userContentColumn = [
        {
            name: "S.No",
            selector: (row, index) => index + 1,
            sortable: true,
            width: "50px"
        },
        {
            name: "Name",
            selector: row => row.cname,
            width: "400px"
        },
        {
            name: "Type",
            selector: row => row.contentType,
            width: "150px"
        },
        {
            name: "Published Date",
            selector: row => convertTimestampToDate(row.publishDate),
            width: "250px"

        },
        {
            name: "Progress",
            selector: row => row.activityProgress,
            width: "150px"

        },
        {
            name: "Last Accessed on",
            selector: row => convertTimestampToDate(row.lastAccessed),
            width: "250px"
        },
        {
            name: "Status",
            selector: row => row.activityStatus,
            width: "150px"
        }
    ];

    //////////////////////////////////////////////// COURSE SUMMARY END ////////////////////////////////////////////



    const authoredCourseListColumn = [
        {
            name: 'S No.',
            selector: (row, index) => index + 1,
            width: '150px',
            wrap: true,
        },
        {
            name: 'Course Name',
            selector: row => row.courseDetails.courseName,
            wrap: true,
            width: "500px"

        },
        {
            name: 'Course Type',
            selector: row => row.courseDetails.courseType,
            wrap: true,
            width: "200px"

        },
        {
            name: 'Starting Date',
            selector: row => <>
                <div>{new Date(row.courseStartDate).getDate()}-{(new Date(row.courseStartDate).getMonth()) + 1}-{new Date(row.courseStartDate).getFullYear()} {new Date(row.courseStartDate).getHours()}:{new Date(row.courseStartDate).getMinutes()}:{new Date(row.courseStartDate).getSeconds()}</div>
            </>,
            wrap: true,
            width: "300px"
        },
        {
            // row.courseDetails.courseClosingDate
            name: 'Closing Date',
            selector: row => <>
                <div>{new Date(row.courseDetails.courseClosingDate).getDate()}-{(new Date(row.courseDetails.courseClosingDate)).getMonth() + 1}-{new Date(row.courseDetails.courseClosingDate).getFullYear()} {new Date(row.courseDetails.courseClosingDate).getHours()}:{new Date(row.courseDetails.courseClosingDate).getMinutes()}:{new Date(row.courseDetails.courseClosingDate).getSeconds()}</div>
            </>,
            wrap: true,
            width: "300px"
        },
        {
            name: 'Action',
            cell: row =>
                <>
                    <button className='btn btn-sm btn-success'
                        onClick={() => {
                            onClickMoreDetails(row.id.courseId, row.courseDetails.courseName);
                            getUserEnrolledByCourse(row.id.courseId)
                        }}
                        disabled={moreDetailsLoading.isLoading ? "true" : ""}
                        style={{ background: "green", border: "0px", whiteSpace: "nowrap" }}>
                        {moreDetailsLoading.isLoading ? (<> {t('loading')}</>) : <>{t('more_details')}</>}
                    </button>

                    <Button
                        onClick={() => {
                            onClickGetCourseSummary(row.id.courseId);
                            setSelectedCourse(row.courseDetails);
                        }}
                        size='sm'
                        style={{ background: "green", border: "0px", whiteSpace: "nowrap", margin: "10px" }}>
                        {isCourseSummaryLoading ? (<> {t('loading')}</>) : <>Summary Report</>}
                    </Button>
                </>,
            wrap: true,

        }

    ]




    /****************************END LIST OF AUTHORED COURSE***************************/
    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        return (
            <FilterDataTable
                onFilter={e => setFilterText(e.target.value)}
                onClear={handleClear}
                filterText={filterText}
            />
        );
    }, [filterText, resetPaginationToggle]);

    const getUserEnrolledByCourse = (courseId) => {
        setQuizReportList([]);
        setCourseId(courseId);
        learnerService.getUserEnrolledByCourse(courseId, tenantId)
            .then(res => {
                setlearnerData(res.data);
                setMoreDetailsLoading({ isLoading: false });
                
            }).catch(err => {
                
                setMoreDetailsLoading({ isLoading: false });
            })
    }

    return (
        <Styles>
            {/* Main Wrapper */}
            <div className="main-wrapper dashboard-page">

                <HeaderTwo />

                {/* Breadcroumb */}
                <BreadcrumbBox title={t('instructor_Dashborad')} />

                <Container fluid>
                    <div className='dashboard-box' style={{ marginTop: "30px", marginBottom: "30px" }}>
                        <div className='registration-title'>
                            <h3 style={{ textAlign: 'center', fontWeight: 'bold' }}>{t('authored_course_list')}</h3>
                            <br></br>
                            <DataTable
                                columns={authoredCourseListColumn}
                                data={authoredCourseListItems}
                                defaultSortAsc={true}
                                striped
                                pagination
                                highlightOnHover
                                customStyles={customStyles}
                                subHeader
                                subHeaderComponent={authoredListSubHeaderComponent}
                            />
                            {/* COURSE SUMMARY STARTS */}

                            {(courseSummaryList?.length > 0) ?
                                <div className="col-lg-12 grid-margin stretch-card">
                                    <div className="card">
                                        <div style={{ padding: "10px" }}>
                                            <Row>
                                                {courseSummaryList?.length > 0 ?
                                                    <>
                                                        <Col md={4}>
                                                            <div style={stylesForSummary.summaryContainer}>
                                                                <div style={stylesForSummary.summaryItem}>
                                                                    <p style={stylesForSummary.summaryLabel}> Enrolled:</p>
                                                                    <p style={stylesForSummary.summaryValue}>{totalEnrolledCourse}</p>
                                                                </div>
                                                                <div style={stylesForSummary.summaryItem}>
                                                                    <p style={stylesForSummary.summaryLabel}> Completed:</p>
                                                                    <p style={stylesForSummary.summaryValue}>{totalCompletedCourse}</p>
                                                                </div>
                                                            </div>
                                                            <div style={stylesForSummary.summaryContainer}>
                                                                <div style={stylesForSummary.summaryItem}>
                                                                    <p style={stylesForSummary.summaryLabel}> In Progress:</p>
                                                                    <p style={stylesForSummary.summaryValue}>{totalInProgressCourse}</p>
                                                                </div>
                                                                <div style={stylesForSummary.summaryItem}>
                                                                    <p style={stylesForSummary.summaryLabel}> Not Started Yet:</p>
                                                                    <p style={stylesForSummary.summaryValue}>{totalNotStartedYet}</p>
                                                                </div>
                                                            </div>
                                                        </Col>

                                                        <Col md={4}>
                                                            <div style={stylesForSummary.summaryContainer}>
                                                                <div style={stylesForSummary.summaryItem}>
                                                                    <p style={stylesForSummary.summaryLabel}>Total Certficate Generated:</p>
                                                                    <p style={stylesForSummary.summaryValue}>{totalGenCert}</p>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={4}>
                                                            <div style={{ marginTop: '10px' }}>
                                                                <Button size='sm' onClick={exportExcel} style={{ marginRight: "10px", marginTop: "10px" }}>{t('export_to_excel')}</Button>
                                                                <Button size='sm' onClick={exportPDF} style={{ marginTop: "10px" }}>{t('export_to_pdf')}</Button>
                                                            </div>
                                                        </Col>
                                                    </>
                                                    :
                                                    <>

                                                    </>
                                                }

                                            </Row>
                                            {/* <Button onClick={() => LearnersDatas()} disabled={submitLoading.isLoading ? "true" : ""} style={{background:"green", border:"0px"}}>{submitLoading.isLoading ? (<> Loading...</>) : (<>Submit</>)}</Button> */}
                                        </div>
                                        <div className="card-body">
                                            <DataTable
                                                columns={columnsForSummary}
                                                data={filteredItems}
                                                defaultSortField="Name"
                                                defaultSortAsc={true}
                                                striped
                                                pagination
                                                highlightOnHover
                                                pointerOnHover
                                                customStyles={customStyles}
                                                subHeader
                                                subHeaderComponent={courseSummarySearchBox}
                                                onRowClicked={handleRowClick}
                                            />
                                        </div>

                                    </div>
                                </div>
                                :
                                <>
                                </>
                            }
                            {
                                selectedUser ?
                                    <>
                                        {
                                            selectedUser && (
                                                <div
                                                    className='selected-user-title'
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        height: '100px',
                                                        backgroundColor: '#f0f0f0',
                                                        borderRadius: '8px',
                                                        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                                                        fontSize: '24px',
                                                        fontWeight: 'bold',
                                                        color: '#333'
                                                    }}
                                                >Learner: {selectedUser?.firstName} {selectedUser?.lastName}
                                                </div>
                                            )
                                        }
                                        <div className="card-body">
                                            <DataTable
                                                columns={userContentColumn}
                                                data={userCourseContentReportfilteredList}
                                                defaultSortField="Name"
                                                defaultSortAsc={true}
                                                striped
                                                pagination
                                                highlightOnHover
                                                customStyles={customStyles}
                                                subHeader
                                                subHeaderComponent={contentSummarySearchBox}
                                            />
                                        </div>
                                    </>
                                    : <></>
                            }
                            {/* COURSE SUMMARY ENDS */}
                            {
                                moreDetailsDiv ?
                                    <>
                                        <Container fluid>

                                            <h5 style={{ textAlign: 'center', fontWeight: 600 }}>{moreDetailsDiv.courseName}</h5>
                                            <br></br>
                                            <label class="control-label" for="name">{t('user_list')} : </label>
                                            <select onChange={(e) => { onEmailIdSelectHandler(); getQuizReport(getCourseId, e.target.value); }} class="custom-select" id="gender2">
                                                <option selected>{t('choose')}</option>
                                                {
                                                    getLearnerData.map((learner, index) => {
                                                        return (
                                                            <option value={
                                                                learner.learnerUsername
                                                            }>
                                                                {learner.firstName} {learner.lastName}
                                                            </option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            {
                                                getEmailListDiv ?
                                                    <Row className='mt-4'>
                                                        <div class="col-8 col-md-4">
                                                            <select onChange={(e) => onSelectHandler(e)} class="custom-select" id="gender2">
                                                                <option selected>{t('choose')}</option>
                                                                <option value="1">{t('quiz_report')}</option>
                                                                {/* <option value="2">Assignment Report</option>
                                                <option value="3">Time Spent Report</option>
                                                <option value="4">Content Access Log</option> */}
                                                                {/* <option value="5">Activity Log</option> */}
                                                            </select>
                                                        </div>
                                                    </Row>
                                                    :
                                                    ''
                                            }
                                            {
                                                selectedState == 1 ?
                                                    <>
                                                        <h5 style={{ textAlign: 'center', fontWeight: 600 }}>Quiz Report</h5>
                                                        <DataTable
                                                            columns={quizReportcolumns}
                                                            data={quizfilteredItems}
                                                            defaultSortField="Name"
                                                            defaultSortAsc={true}
                                                            striped
                                                            pagination
                                                            highlightOnHover
                                                            customStyles={customStyles}
                                                            subHeader
                                                            subHeaderComponent={subHeaderComponent}
                                                        />
                                                    </>
                                                    :
                                                    <></>
                                            }
                                        </Container>

                                    </>
                                    :
                                    ''
                            }
                        </div>
                    </div>
                </Container>


                {/* Footer 2 */}
                <FooterTwo />
                <Modal centered show={getQuizModal.show} onHide={() => setQuizModalHide()} backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter" style={{ alignContent: "center" }}>
                            {t('quiz_details')}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <label>{t('attempt_number')}</label>
                            <select
                                onChange={(e) => setSelectedAttempt(e.target.value)}

                                class="custom-select" id="QuizReport">
                                <option selected>{t('choose')}</option>
                                {getQuizReportCard.map((data, index) => {
                                    return (
                                        <option value={data.attemptNumber}>{data.attemptNumber}</option>
                                    )
                                })}
                            </select>
                        </div>
                        {getQuizReportCard.map((data, index) => {
                            return (
                                <div>
                                    {(getSelectedAttempt == data.attemptNumber) ? (<Card style={{ border: "0px", borderRadius: "5px", boxShadow: "3px 3px 5px #d6d6d6", margin: "10px" }}>
                                        <div style={{ marginLeft: "15px", marginRight: "15px" }}>
                                            <Row
                                                style={data.quizResult === 'fail' ? { backgroundColor: "#cf2121", color: "white", borderRadius: "5px 5px 0px 0px", border: "0px", padding: "5px" }
                                                    : data.quizResult === 'pass' ? { backgroundcolor: "#665245", color: "white", borderRadius: "5px 5px 0px 0px", border: "0px", padding: "5px" }
                                                        : { backgroundColor: "#182B49", color: "white", borderRadius: "5px 5px 0px 0px", border: "0px", padding: "5px" }}
                                            >{t('attempt_number')} {data.attemptNumber} : {(data.quizResult === 'fail') ? (<>{t('fail')}</>) : ((data.quizResult === 'pass') ? <>{t('pass')}</> : <>{t('pending')}</>)}
                                            </Row>
                                            {(data.quizStatus !== 'attempted') && (<Row style={{ padding: "3px" }}>
                                                <Col>{t('questions_count')}: </Col>
                                                <Col>{data.quizTotalQuestionsCount}</Col>
                                            </Row>)}
                                            {(data.quizStatus !== 'attempted') && (<Row style={{ padding: "3px" }}>
                                                <Col>{t('questions_attempted')}: </Col>
                                                <Col>{data.quizTotalAttempted}</Col>
                                            </Row>)}
                                            {(data.quizStatus !== 'attempted') && (<Row style={{ padding: "3px" }}>
                                                <Col>{t('questions_not_attempted')} : </Col>
                                                <Col>{data.quizTotalNotAttempted}</Col>
                                            </Row>)}
                                            {(data.quizStatus !== 'attempted') && (<Row style={{ padding: "3px" }}>
                                                <Col>{t('achieved_total_core')} : </Col>
                                                <Col>{data.quizAchievedScore} / {data.quizTotalScore}</Col>
                                            </Row>)}
                                            <Row style={{ padding: "3px" }}>
                                                <Col>{t('result')} : </Col>
                                                <Col>{(data.quizResult === 'fail') ? (<>{t('fail')}</>) : ((data.quizResult === 'pass') ? <>{t('pass')}</> : <>{t('pending')}</>)}</Col>
                                            </Row>
                                            <Row style={{ padding: "3px" }}>
                                                <Col>{t('quiz_status')} : </Col>
                                                <Col>{(data.quizStatus === 'completed') ? (<>{t('completed')}</>) : (<>{t('attempted')}</>)}</Col>
                                            </Row>
                                        </div>
                                    </Card>) : null}
                                </div>
                            )
                        })

                        }

                    </Modal.Body>
                    <Modal.Footer style={{ justifyContent: "right" }}>
                        <Button variant="secondary" onClick={() => setQuizModalHide()}>
                            {t('cancel')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </Styles>

    );
}

export default InstructorDashboard;