import moment, { duration } from 'moment';
import React, { useEffect, useState } from 'react'
import { Button, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import instructorService from '../../../services/instructorService';
import swal from 'sweetalert';
import "./UpdateUserCourseDuration.css";
import DataTable from 'react-data-table-component';
import { useHistory } from 'react-router-dom';


const UpdateUserCourseDuration = ({ courseId }) => {

  const history = useHistory();

  const [courseDuration, setCourseDuration] = useState(0);
  const [courseAllUsersData, setCourseAllUsersData] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);


  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);

  const handleRowSelected = React.useCallback(state => {
    setSelectedRows(state.selectedRows);
  }, []);

  const extractUsernamesAndUpdate = () => {
    const selectedUsernames = selectedRows?.map(row => row.learnerUsername);
    setSelectedUsers(selectedUsernames);
  };

  useEffect(() => {
    extractUsernamesAndUpdate();
  }, [selectedRows]);


  const [errors, setErrors] = useState({});

  const onDurationChange = (duration) => {

    setCourseDuration(duration);
  }

  const onDurationBlur = () => {
    const errors = {};
    if (isNaN(courseDuration)) {
      errors.duration = "Duration must be a number";
    } else if (courseDuration < 0) {
      errors.duration = "Duration must be number and cannot be negative";
    }

    setErrors(errors);
  }

  useEffect(() => {
    getAllUsersByCourse();
  }, [courseId])


  const getAllUsersByCourse = () => {
    instructorService.getUsersByCourseId(courseId)
      .then((res) => {
        if (res.status == 200) {
          setCourseAllUsersData(res.data);
        }
      })
      .catch((err) => {
        swal("Error", "Error", "error");
      })
  }

  const convertTimestampToDate = (timestamp) => {
    return moment(timestamp).format('Do MMMM YYYY');
  };

  const durationColumns = [
    {
      name: "Name",
      selector: row => row.firstName + row.lastName,
      wrap: true,
    },
    {
      name: "Email",
      selector: row => row.email,
      wrap: true,
    },
    {
      name: "Course Start Date",
      selector: row => convertTimestampToDate(row.courseSDate),
      wrap: true,
      sortable: true,
    },
    {
      name: "Course End Date",
      selector: row => convertTimestampToDate(row.courseEDate),
      wrap: true,
      sortable: true,
    },
    {
      name: "Updated On",
      selector: row => convertTimestampToDate(row.updatedOn),
      sortable: true,
      wrap: true,
    }
  ];

  const onSubmitButtonClick = () => {

    if (Object.keys(errors).length !== 0) {
      swal("Warning", "Please Check the duration again", "warning")
    } else {
      instructorService.updateUserCourseDuration(courseId, courseDuration, selectedUsers)
        .then((res) => {
          if (res.status === 200) {
            swal("Success", "Successfully Updated the Schedules", "success")
            getAllUsersByCourse();
          }
        })
        .catch((err) => {
          swal("Error", "Error", "error");
        })
        .finally(() => {
          setSelectedUsers([]);
          setCourseDuration(0);
          setToggleCleared(!toggleCleared);
        });
    }

  }


  const handleGoBack = () => {
    history.push({
      pathname: '/ViewCourses',
      state: { showCourseEditModal: true, courseId }
    });
  };

  

  return (
    <div className='main-user-course-duration-update-page'>
      <div className='user-course-duration-update-area'>
        <Row>
          <Col md={8}>
            <div className='input-area-duration'>
              <label className='label-duration'>User(s) Course Duration Extend(in Days):
                <OverlayTrigger
                  overlay={
                    <Tooltip id="tooltip-disabled">
                      Enter the Duration by which you want to extend the Course Duration (in Days) of User(s)
                    </Tooltip>
                  }
                >
                  <i className="fa fa-info-circle" style={{ fontSize: '18px' }}></i>
                </OverlayTrigger>
              </label>
              <input
                type="number"
                className="courseDuration-form"
                value={courseDuration}
                onChange={(e) => onDurationChange(e.target.value)}
                onBlur={() => onDurationBlur()}
                placeholder={"Enter the Course Commencement Date"}
                min={0}
              />
              {errors?.duration && <span className="error-message">{errors?.duration}</span>}
            </div>
          </Col>
          <Col md={4}>
            <div className='submit-button-area'>
              <div className='d-flex justify-content-end'>
                <Button
                  size='lg'
                  className='submit-button mr-2 btn-success' // Add margin-right for spacing
                  onClick={onSubmitButtonClick}
                  disabled={!(selectedUsers?.length > 0) || !(courseDuration > 0)}
                >
                  Submit
                </Button>
                <Button
                  size='lg'
                  className='goback-button btn-info'
                  onClick={handleGoBack}
                >
                  Go Back
                </Button>
              </div>
            </div>
          </Col>
        </Row>


        <DataTable
          columns={durationColumns}
          data={courseAllUsersData}
          pagination
          selectableRows
          onSelectedRowsChange={handleRowSelected}
          clearSelectedRows={toggleCleared}
        />
      </div>
    </div>
  )
}

export default UpdateUserCourseDuration